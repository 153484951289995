import React from 'react';
import { Route, Routes } from 'react-router-dom'
import './App.css';

import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Portfolio from './pages/Portfolio'

import Navbar from './components/Navbar';
import Footer from './components/Footer';


function App() {
  return (
    <div className="app">
      <>
        <Navbar />
        <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/portfolio" exact element={<Portfolio />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/contact" exact element={<Contact />} />                               
        </Routes>        
        <Footer />
     </>
      
    </div>
  )
}

export default App;
