import React from 'react'
import './Services.css'
import ServiceImage from '../images/helen-evenchen-homepage-banner10.jpg';
import { FiTarget } from 'react-icons/fi';
import { FaFunnelDollar } from "react-icons/fa";
import { GiBrain } from "react-icons/gi"
import { BiCodeAlt } from "react-icons/bi"


function Services() {
  return (
    <div id='services' className='services'>
        <div className="cards-container">
            <div className='card service1' style={{ backgroundImage: `url(${ServiceImage})` }}>
                <div className="overlay"></div>
                <div className="card-content">
                    <div className='card-header' >
                        <BiCodeAlt className='icon'/>
                        <h3>Front-end Dev</h3>
                    </div>
                    <div className='card-text'>
                         <p>Mobile apps and web development using Html, Css, JS, React JS, React Native, Wordpress, and LiquidJS</p>
                    </div>
                </div>
            </div>
            <div className='card service2' style={{ backgroundImage: `url(${ServiceImage})` }}>
                <div className="overlay"></div>
                <div className="card-content">
                    <div className='card-header' >
                        <FiTarget className='icon' />
                        <h3>Digital Advertising</h3>
                    </div>
                    <div className='card-text'>
                        <p> Digital advertising for green, boutique, premium, and luxury brands on Google, Facebook, and Youtube.</p>
                    </div>
                </div>
            </div>
            <div className='card service3' style={{ backgroundImage: `url(${ServiceImage})` }}>
                <div className="overlay"></div>  
                <div className="card-content"> 
                    <div className='card-header' >
                         <GiBrain className='icon' />
                        <h3>Consulting</h3>
                    </div> 
                    <div className='card-text'>

                        <p>Consumer behavior consulting with to improve website UI & UX and increase the effectiveness of digital advertising</p>
                    </div>
                </div>
            </div>
            <div className='card service4' style={{ backgroundImage: `url(${ServiceImage})` }}>
                <div className="overlay"></div>
                <div className="card-content">
                    <div className='card-header' >
                        <FaFunnelDollar className='icon' />
                        <h3>CRO</h3>
                    </div>
                    <div className='card-text'>

                        <p>Helping digital stores implement behavioral psychology to enhance the shopper exprience and increase sales</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Services