import React, { useState, useRef } from 'react';
import Logo from '../images/logowhite.png';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { CiMenuBurger } from 'react-icons/ci';
import { HiBars3 } from 'react-icons/hi2';
import { FaTimes, FaBars } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const navRef = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    navRef.current.classList.toggle('responsive');
    document.body.style.backgroundColor = showMenu ? 'rgba(0, 0, 0, 0.25)' : 'transparent';
  };

  return (
    <header className={`navbar ${showMenu ? 'transparent' : ''}`}>
      <div className='logo-container'>
        <Link to='/'>
        {!showMenu && <img src={Logo} alt='Logo' className='logo' />}
        </Link>
      </div>
      <div className='nav-container'>
        <nav className={`nav ${showMenu ? 'responsive' : ''}`} ref={navRef} onClick={toggleMenu}>
          <Link to='/'>Home</Link>
          <Link to='/portfolio'>Portfolio</Link>
          <Link to='/about'>About</Link>
          <Link to='/contact'>Contact</Link>
          <button className='nav-btn nav-close-btn'>
            <IoClose color="#ffffff" size='22' />
          </button>
        </nav>
      </div>
      {!showMenu && (
        <button className='nav-btn' onClick={toggleMenu}>
          <HiBars3 color="#ffffff" size='40' />
        </button>
      )}
    </header>
  );
}

export default Navbar;

