import React from 'react';
import './Home.css';
import '../App.css'
import { Link } from 'react-router-dom';

import Cube from '../components/Cube'
import Hero from '../components/Hero'
import Services from '../components/Services';
import Gallery from '../components/Gallery';





function Home() {
  return (
    <div className='home'>
      <>
      <Hero />
      <h2>What I do</h2>
      <hr></hr>
      <Services />
      <h2>Technologies I use</h2>
      <hr></hr>
      <Cube />          
      <h2>Recent Projects</h2>
      <hr></hr>
      <Gallery />
      
      </>     
      
    </div>
  )
}

export default Home