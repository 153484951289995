import React from 'react';
import './Gallery.css';

import Advertising from '../images/advertising-bs2.jpg';
import Ramen from '../images/ramen.jpg';
import Stock from '../images/stock.jpg';
import Fashion from '../images/so-chonky2.jpg';
import Save from '../images/save-cat.jpg';
import Shopping from '../images/shopping.jpg';

function GalleryItem({ imageSrc, buttonText, buttonLink }) {
  return (
    <div className="gallery-item">
      <img src={imageSrc} alt="" />
      <a href={buttonLink} target="_blank" rel="noopener noreferrer">
        <button className="gallery-button">{buttonText}</button>
      </a>
    </div>
  );
}

function Gallery() {
  return (
    <div className="gallery">
      <div className="col">
        <GalleryItem
          imageSrc={Save}
          buttonText="Save a Cat"
          buttonLink="https://github.com/helenec295/save-cat"
        />
        <GalleryItem
          imageSrc={Shopping}
          buttonText="shopping app"
          buttonLink="https://github.com/helenec295/shopping-app"
        />
      </div>
      <div className="col">
        <GalleryItem
          imageSrc={Ramen}
          buttonText="ramen everywhere"
          buttonLink="https://helenec295.github.io/ramen-everywhere/"
        />
      </div>
      <div className="col col-large">
        <div className="row">
          <GalleryItem
            imageSrc={Fashion}
            buttonText="fashion store"
            buttonLink="https://helenec295.github.io/fashion-store/"
          />
          <GalleryItem
            imageSrc={Advertising}
            buttonText="advertising bs generator"
            buttonLink="https://helenec295.github.io/bs-generator/"
          />
        </div>
        <GalleryItem
          imageSrc={Stock}
          buttonText="rsu stock tracker"
          buttonLink="https://helenec295.github.io/rsu-stock-tracker/"
        />
      </div>
    </div>
  );
}

export default Gallery;
