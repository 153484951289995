import React, { useRef, useState } from 'react'
import './ContactForm.css'
import '../App.css'
import { IoIosSend } from 'react-icons/io'
import emailjs from '@emailjs/browser'

function ContactForm() {
    const form = useRef();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm (
        'service_ecvh0oa', 
        'template_gblmsve', 
        form.current, 
        '6QrVD9eWb4CYzRRkN' 
        )
        .then((result) => {
            console.log(result.text);
            console.log('message sent');
            setIsSubmitted(true);
        }, (error) => {
            console.log(error.text);
        })
    }

    return (
      
        <div className='contact-form'>
          {isSubmitted ? (
            <p>Your message was sent successfully!</p>
          ) : (
            <form ref={form} onSubmit={sendEmail} method='POST'>
              <input name='user_name' placeholder='Name' type='text' />
    
              <input name='user_email' placeholder='Email' type='email' />
    
              <input name='subject' placeholder='Subject' type='text' />
    
              <textarea rows='7' name='message' placeholder='Message' required></textarea>
    
              <button>Send&nbsp;&nbsp;<IoIosSend /></button>
            </form>
          )}
        </div>
      
    );
  }
  

export default ContactForm