import React from 'react'
import './Hero.css'
import { Link } from 'react-router-dom';
import BannerImage from '../images/helen-evenchen-homepage-banner10.jpg';
import '../App.css'



function Hero() {
  
  
  return (
    <div className="hero">

        <div className="hero-image" style={{ backgroundImage: `url(${BannerImage})` }}> 
          <div className="hero-content" >
              <h1>Helen Evenchen</h1>
              <h3> Front-end Developer</h3>
              <p>
                Digital strategist. Consumer psychology consultant. Foodie. Bookworm. Crazy cat lady. I like building useful and pretty things for shopping interactions online. 
              </p>
              <div className='btn-container'>                                  
              <Link to="/portfolio">
                  <button className='portfolio-btn'>Portfolio</button>
              </Link>
              <Link to="/about">
                  <button className='about-btn'>About</button>
              </Link>
              </div>             
          </div>
          {/* <p className="scrolldown">
            <a className="smoothscroll" href="#services">
             <i class="fa-solid fa-chevron-down"></i>
            </a>
          </p> */}
      </div>
    </div>
  )
}

export default Hero