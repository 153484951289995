import React from 'react'
import { BsBoxArrowUpRight } from "react-icons/bs";
import { RxExternalLink } from "react-icons/rx";



function PortfolioItem({ image, name, desc, made, url, icon}) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" className="portfolioItem">
        <div className='project-image' style={{ backgroundImage: `url(${image})` }}></div>
        <div className='project-info'>
          <h3>{name}</h3>
          <p>
            {made}&nbsp; {desc}
          </p>
        </div>
        <p className="icon">
          visit {icon} <RxExternalLink size="16" weight="500" />
        </p>
    </a>
    );
  }

export default PortfolioItem