import React from 'react';
import Advertising from '../images/advertising-bs1.jpg'
import Chicken from '../images/minecraft-chicken.jpg'
import Insightout from '../images/helen-evenchen-homepage-banner.jpg'
import Pizza from '../images/pizza.jpg'
import SoChonky from '../images/so-chonky.jpg'
import Wheel from '../images/wheel-fortune.jpg'
import Cloud from '../images/cloud-abstract.jpg'
import Lida from '../images/lida.jpg'
import Piggy from '../images/piggy-bank.jpg'
import Promotion from '../images/sale.jpg'
import Ramen from '../images/ramen.jpg'
import Stock from '../images/stock.jpg'
import Cat from '../images/adopt-cat.jpg'
import Fashion from '../images/so-chonky.jpg'
import Save from '../images/save-cat.jpg'
import Old from '../images/old.png'
import Shopping from '../images/shopping.jpg'
import Zebra from '../images/zebra.jpg'
import { SiJavascript } from 'react-icons/si'
import { FaCss3Alt, FaReact, FaWordpressSimple, FaShopify, FaGithub } from 'react-icons/fa'
import { TbBrandReactNative } from 'react-icons/tb'




export const PortfolioList = [
    {
        name: "What Is That App",
        image: Zebra, 
        desc: 'Mobile image classification app that lets users classify objects around them using their phone camera. Uses Google Cloud Vision API for image analysis and classification.',
        made: <TbBrandReactNative color='#61dbfb' size='16' />,
        url: 'https://github.com/helenec295/what-is-that-app',
        icon: <FaGithub size='16' />     
    },
    {
        name: "Shopping App",
        image: Shopping, 
        desc: 'Mobile shopping app offering fashion sneakers. Uses MongoDB for product database & Stripe API for payments processing.',
        made: <TbBrandReactNative color='#61dbfb' size='16' />,
        url: 'https://github.com/helenec295/shopping-app',
        icon: <FaGithub size='16' />     
    },
    {
        name: "Save A Cat App",
        image: Save, 
        desc: 'Mobile app intended for cat rescue and adoption organizations with the goal of helping as many cats a possible to fing a home. Uses PetFinder API with Axios',
        made: <TbBrandReactNative color='#61dbfb' size='16' />,
        url: 'https://github.com/helenec295/save-cat/',
        icon: <FaGithub size='16' />     
    },
    {
        name: "Fashion Store",
        image: Fashion, 
        desc: 'Online store offering fashion clothing and fun accessories for large and oversized cats. Created using Strapi API CMS and Stripe API for payments processing',
        made: <FaReact color='#61dbfb' size='16' />,
        url: 'https://helenec295.github.io/fashion-store/',
        icon: <FaGithub size='16' />     
    },
    {
        name: "Cats Happen",
        image: Cat, 
        desc: 'Cats choose their humans but you can insrease your luck of being chosen by finding cats availiable for adoption within 10miles from wherever you are. PetFinder API & Axios ',
        made: <FaReact color='#61dbfb' size='16' />,
        url: 'https://helenec295.github.io/cats-happen/',
        icon: <FaGithub size='16' />     
    },
    {
        name: "Insightout Digital",
        image: Insightout, 
        desc: 'My advertising agency website, the hub of everything I do and where I also write a blog on ecommerce shopping behavior and psychology of pricing.',
        made: <FaWordpressSimple color="#00749C" size='16' margin='5' />,
        url: 'https://insightoutdigital.com/',
        icon: ''              
    },
    
    {
        name: "Advertising BS Generator",
        image: Advertising, 
        desc: 'The generator uses common lingo used by the advertising industry to produce random expressions',
        made: <SiJavascript color="#F0DB4F" size='16' />,
        url: 'https://helenec295.github.io/bs-generator/',
        icon: <FaGithub size='16' />     
    },    
    {
        name: "Promotion Cost Calculator",
        image: Promotion, 
        desc: 'Easy to use calculator that lets marketing managers estimate the total cost of a promotion, including revenue lost to price drop and the promotion advertising budget.',
        made: <FaReact color='#61dbfb' size='16' />,
        url: 'https://helenec295.github.io/discount-calculator/',
        icon: <FaGithub size='16' />     
    },

    {
        name: "Ramen Everywhere",
        image: Ramen, 
        desc: 'Ramen is my favorite food so this app helps me find ramen anywhere I go using Yelp API',
        made: <FaReact color='#61dbfb' size='16' />,
        url: 'https://helenec295.github.io/ramen-everywhere/',
        icon: <FaGithub size='16' />
    },
    {
        name: "Budget Assistant",
        image: Piggy, 
        desc: 'In development. Simple daily expense management and budget tracking to provide clear financial information to the user and to develop good financial habits',
        made: <FaReact color='#61dbfb' size='16' />,
        url: 'https://helenec295.github.io/expense-tracker-app/',
        icon: <FaGithub size='16' />
    },    
    {
        name: "RSU Stock Tracker",
        image: Stock, 
        desc: 'Employees can easily track their employers share price to help them decide whether to sell or hold their vested RSUs using TwelveData API & Axios',
        made: <FaReact color='#61dbfb' size='16' />,
        url: 'https://helenec295.github.io/rsu-stock-tracker/',
        icon: <FaGithub size='16' />
    },
    {
        name: "Spin The Wheel",
        image: Wheel, 
        desc: 'Lucky spinner wheel used in ecommerce as a gamification tool to collect users email addresses in exchange for spinning the wheel to win prizes and coupon codes',
        made: <SiJavascript color="#F0DB4F" size='16' />,
        url: 'https://helenec295.github.io/lucky-spinner/',
        icon: <FaGithub size='16'/>
    },
    {
        name: "Minecraft Chicken",
        image: Chicken, 
        desc: 'Animated backgroud homepage, built for a Minecraft lover and inspired by the Minecraft chicken rain animation.',
        made: <FaCss3Alt color="#264de4" size='16' />,
        url: 'https://helenec295.github.io/minecraft-chicken/',
        icon: <FaGithub size='16' />
    },
    {
        name: "Restaraunts Search",
        image: Pizza, 
        desc: 'App that searches and retrieves restaraunt info by location and cuisine style using Yelp API',
        made: <FaReact color='#61dbfb' size='16' />,
        url: 'https://helenec295.github.io/rest-search-app/',
        icon: <FaGithub size='16' />
    },
    
    {
        name: "Cloud Abstract",
        image: Cloud, 
        desc: 'Professional blog about virtualization, the cloud, and networking',
        made: <FaWordpressSimple color="#00749C" size='16' />,
        url: 'https://cloud-abstract.com/',
        icon: ''
    },
    {
        name: "Lida Consulting Group",
        image: Lida, 
        desc: 'Lida helps highly skilled professionals achieve their relocation goals through relocation advice, carrer planning, and interview coaching',
        made: <FaWordpressSimple color="#00749C" size='16' />,
        url: 'https://lidagroupconsulting.com/',
        icon: ''
    },
    {
        name: "So Chonky",
        image: Old, 
        desc: 'Online store offering fun accessories for large and oversized cats. Inactive.',
        made: <FaShopify color="#96bf48" size='16' />,
        url: '',
        icon: ''
        
    },
    

]