import React from 'react'
import './Cube.css'



function Cube() {
  return (
    <div className='cube'>
      <div className='cube-container'>
          
          <div className='cube-spinner'>
                <div className='face front'> 
                <i class="fab fa-html5" style={{ color: '#e34c26' }}></i>
              </div>
              <div className='face back'>
                <i class="fab fa-css3-alt" style={{ color: '#264de4' }}></i>         
              </div>
              <div className='face right'>
                <i class="fab fa-square-js" style={{ color: '#F0DB4F' }}></i>
              </div>
              <div className='face left'>
                <i class="fab fa-react" style={{ color: '#61dbfb' }}></i>
              </div>
              <div className='face top'>
                <i class="fab fa-wordpress" style={{ color: '#00749C' }}></i>
              </div>
              <div className='face bottom'>
                <i class="fab fa-shopify" style={{ color: '#96bf48' }}></i>
            </div>
            
        </div>
      </div>
    </div>
  )
}

export default Cube