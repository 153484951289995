import React from 'react'
import ContactForm from '../components/ContactForm'
import './Contact.css'
import ContactImage from '../images/dandelion-overlay3.jpg';

import Seed1 from '../images/dandelion-seed1.png'
import Seed2 from '../images/dandelion-seed2.png'
import Seed3 from '../images/dandelion-seed2.png'
import Seed4 from '../images/dandelion-seed2.png'
import Seed5 from '../images/dandelion-seed2.png'


function Contact() {
  return (
    <div className='contact-page' >
      <h2>Contact</h2>
      <hr></hr>
      <ContactForm />

      <div class="dandelion-seeds">
            <img classname='seed' src={Seed1} alt="" />
            <img classname='seed' src={Seed2} alt="" />
            <img classname='seed' src={Seed3} alt="" />
            <img classname='seed' src={Seed5} alt="" />
            <img classname='seed' src={Seed1} alt="" />
            <img classname='seed' src={Seed2} alt="" />
            <img classname='seed' src={Seed3} alt="" />
            <img classname='seed' src={Seed5} alt="" />
      </div>

        
    </div>
  )
}

export default Contact