import React from 'react'
import { PortfolioList } from '../helpers/PortfolioList';
import PortfolioItem from '../helpers/PortfolioItem';
import './Portfolio.css'


function Portfolio() {
  return (
    <div className='portfolio'>
      <h2 className="portfolioTitle">Portfolio</h2>
      <hr></hr>
      <div className="portfolioList">
        {PortfolioList.map((portfolioItem, key) => {
          return (
            <PortfolioItem 
            key={key}
            image={portfolioItem.image} 
            name={portfolioItem.name} 
            desc={portfolioItem.desc}
            made={portfolioItem.made}
            url={portfolioItem.url}
            icon={portfolioItem.icon}  
            />
          );
        })}
      </div>
      

    </div>
  )
}

export default Portfolio