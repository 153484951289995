import React from 'react'
import './About.css'
import AboutImage from '../images/helen-evenchen.jpg'
import '../App.css'
import { SiGoogleads, 
          SiGoogletagmanager, 
          SiGoogleanalytics, 
          SiJavascript, 
          SiExpo, 
          SiRedux, 
          SiWoo,
          SiJest,
          SiMongodb,
          SiFirebase,
          SiGooglecloud
          } from 'react-icons/si'
import { FaHtml5, 
        FaCss3Alt, 
        FaReact, 
        FaWordpress, 
        FaShopify, 
        FaNodeJs,
        FaNpm,
        FaFacebookF,
        FaYoutube,
        FaPinterestP  } from 'react-icons/fa'
import { TbBrandReactNative } from 'react-icons/tb'
import { 
  AiOutlineGoogle, 
  AiOutlineDownload
} from 'react-icons/ai'
import { HiOutlineDocumentDownload } from 'react-icons/hi'
import { Link } from 'react-router-dom';
import Resume from '../images/helen-evenchen-resume.pdf'


function About() {
  return (
    <div className='about-page'>
      <h2>About me</h2>
      <hr></hr>
      <div className='about'>
      
        <div className='leftImage' >      
          <img src={AboutImage} alt=''></img>
        </div>

      
        <div className='rightText'>
              <p> 
                Welcome! I'm Helen Evenchen, a digital marketing strategist who has embarked on an exciting transition into the world of front-end development. With a strong foundation in consumer psychology and over 15 years of experience helping businesses thrive, I am now driven by the desire to craft captivating and user-centric web and mobile applications, with a focus on React.js and React Native. <br /><br />
                Throughout my career, I have been privileged to explore the dynamic realms of digital marketing and consumer behavior. Armed with an MBA in consumer behavior, I have worked closely with businesses of all sizes since 2004, guiding them towards exponential growth. As the marketing psychology geek behind Insightout Digital, I have collaborated with boutique, fashion, premium, and luxury brands, providing research and consulting services. Together, we have transformed behavioral insights into impactful digital campaigns that truly resonate with target audiences.<br /><br />
                During my journey, I repeatedly encountered a common challenge faced by businesses—a high level of engagement and traffic, yet disappointingly low conversion rates. This realization ignited my curiosity about the technical aspects of conversion rate optimization (CRO). To delve deeper, I embarked on a path of self-learning, exploring tracking tag implementation, HTML/CSS/JS, WordPress, and Shopify. As I honed my skills, my fascination with programming steadily grew.<br /><br />
                Motivated by my growing interest, I made the decision to take programming seriously. I began my journey by enrolling in the Frontend Engineer certification class at Codecademy, a pivotal step towards my goal of becoming a front-end developer. The course equipped me with the essential tools and knowledge needed to build a solid foundation in React.js and React Native. Concepts such as components, JSX, state management, lifecycle methods, and React Hooks fascinated me, as they unlocked a faster and more efficient way to create robust web applications. Inspired by these discoveries, I even rebuilt my own portfolio using the power of React. Furthermore, I recognized that React's ability to create clean and intuitive user interfaces (UI) was crucial. As a digital specialist, I have witnessed the impact of well-designed UIs on user experience and conversion rates, and I strive to apply this knowledge to enhance the usability of websites.<br /><br />
                My journey into front-end development also led me to explore the realm of mobile app development using React Native. By building mobile apps from scratch, I gained hands-on experience in handling UI components, navigation, device-specific features, and platform differences. Additionally, I developed expertise in integrating APIs, making HTTP requests, handling responses, and managing data flow between the frontend and backend—a vital skill for building dynamic web and mobile applications.<br /><br />
                To further expand my skills, I delved into state management libraries and learned Redux, which is widely used in conjunction with React.js and React Native. This knowledge enabled me to effectively manage app state, including actions, reducers, and middleware, enhancing my overall development capabilities.<br /><br />
                I also gained strong proficiency in JavaScript, including its modern features such as ES6+, asynchronous programming, closures, and functional programming concepts. This comprehensive understanding of JavaScript empowers me to develop efficient and robust code that meets the complex requirements of modern applications.<br /><br />
                With a deep appreciation for continuous learning, I recognize the importance of staying updated with the latest trends, libraries, and best practices. By actively engaging with documentation, online tutorials, blog posts, and developer conferences, I ensure that my skills remain sharp and aligned with industry standards.<br /><br />
                Throughout my career and educational journey, I have consistently demonstrated a passion for learning and self-improvement. I believe that my ability to adapt, advance my education, and embrace new challenges has been key to my success.<br /><br />
                
              </p>
              <div className='btn-container'>                                  
              <a href={Resume} download='HelenEvenchenResume' >
                  <button className='resume-btn'>Download Resume <AiOutlineDownload size='14' style={{verticalAlign: 'middle'}} /></button>
              </a>
              
              </div>    
        </div>      
      
      </div>

      <div className='tech-stack'>
        <h3>Tech stack</h3>
        <hr></hr>         
        
        <div className='wrapper'>
          <div className='box'>
              <span><FaHtml5 className='t-icon' color="#e34c26"/></span>
              <span><FaCss3Alt className='t-icon' color="#264de4"/></span>
              <span><SiJavascript className='t-icon' color="#F0DB4F"/></span>
              <span><FaReact className='t-icon' color="#61dbfb"/></span>
              <span><SiRedux className='t-icon' color='#764abc' /></span>
              <span><TbBrandReactNative className='t-icon' color='#61dbfb' /></span>
              <span><SiMongodb className='t-icon' color='#4DB33D' /></span>
              <span><SiGooglecloud className='t-icon' color='#4285F4' /> </span>
              <span><SiFirebase className='t-icon' color='#FFA611' /></span>
              <span><SiExpo className='t-icon'/></span>
              <span><FaNpm className='t-icon' color='#CC3534'/></span>
              <span><FaNodeJs className='t-icon' color="#68A063" /></span>
              <span><SiJest className='t-icon' color='c03b13' /></span>
              <span><FaWordpress className='t-icon' color="#00749C"/></span>
              <span><SiWoo className='t-icon' color='#7F54B3' /></span>
              <span><FaShopify className='t-icon' color="#96bf48"/></span>
              <span><SiGoogleads className='t-icon' color='#4285F4' /></span>
              <span><SiGoogletagmanager className='t-icon' color='#4285F4'/></span>
              <span><SiGoogleanalytics className='t-icon' color='#E37400'/></span>
              <span><AiOutlineGoogle className='t-icon' color='#4285F4'/></span>
              <span><FaFacebookF className='t-icon' color='#3b5998' /></span>
              <span><FaYoutube className='t-icon' color='#c4302b' /></span>
             
              
            
          </div>

        </div>
      </div>

    </div>
  )
}

export default About